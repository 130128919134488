import { useStaticQuery, graphql } from 'gatsby'

/**
 * Gets all shopify products
 * @returns array containing all products
 */
export default function GetProduct() {
  const data = useStaticQuery(graphql`{
  allShopifyProduct(sort: {updatedAt: DESC}) {
    edges {
      node {
        id
        title
        handle
        createdAt
        productType
        tags
        metafields {
          value
          key
        }
        media {
          ... on ShopifyMediaImage {
            image {
              originalSrc
            }
          }
        }
        descriptionHtml
        shopifyId
        options {
          shopifyId
          name
          values
        }
        variants {
          id
          availableForSale
          price
          title
          shopifyId
          selectedOptions {
            name
            value
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
}`)
  const type = data.allShopifyProduct.edges.map(node => {
    return {
      node,
    }
  })
  return {
    type,
  }
}
